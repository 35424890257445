export const METADATALOADED = 'METADATALOADED';

export const APPLICATIONSELECTED = 'APPLICATIONSELECTED';


export const FUNCTIONSELECTED = 'FUNCTIONSELECTED';

export const APPDIALOGOPEN = 'APPDIALOGOPEN';

export const APPDIALOGCLOSED = 'APPDIALOGCLOSED';

export const FUNCDIALOGOPEN = 'FUNCDIALOGOPEN';

export const FUNCDIALOGCLOSED = 'FUNCDIALOGCLOSED';

export const TREESELECTORDIALOGOPEN = 'TREESELECTORDIALOGOPEN';

export const TREESELECTORDIALOGCLOSED = 'TREESELECTORDIALOGCLOSED';


export const TREESELECTED = 'TREESELECTED';

export const TREEPERSONSELECTED = 'TREEPERSONSELECTED';

export const DIAGRAMSELECTED = 'DIAGRAMSELECTED';

export const DISPLAYDIAGRAMCONTROLS = 'DISPLAYDIAGRAMCONTROLS';

export const HIDEDIAGRAMCONTROLS = 'HIDEDIAGRAMCONTROLS';

export const TOGGLEDIAGRAMCONTROLS = 'TOGGLEDIAGRAMCONTROLS';

export const NEWLOCATIONS = 'NEWLOCATIONS';

export const ADDCACHETREE = 'ADDCACHETREE';

export const ADDCACHETREEPERSON = 'ADDCACHETREEPERSON';

export const SETTITLE = 'SETTITLE';
 
export const CONTROLPANELTOOLSDIALOGCLOSED = 'CONTROLPANELTOOLSDIALOGCLOSED';

export const CONTROLPANELTOOLSDIALOGOPEN = 'CONTROLPANELTOOLSDIALOGOPEN';
import React, { Component } from 'react';
import Persons from './table/adb/persons/Person.jsx';


function TBirths(props) {

  
    return (
        <div>
          <Persons/>
        </div>
    );

}


export default TBirths;

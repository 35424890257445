import React, { Component } from 'react';
import Trs from './table/dna/treerecsearch/trs.jsx'

function FTMTrees(props) {

    const {className, theme, classes} = props;

    return (
        <div>
          <Trs></Trs>
        </div>
    );

}


export default FTMTrees;

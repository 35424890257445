import React, { Component } from 'react';
import LincsWills from './table/wills/LincsWills.jsx'


function WLincolnshire(props) {

 
    return (
        <div>
          <LincsWills></LincsWills>
        </div>
    );

}


export default WLincolnshire;

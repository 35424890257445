import React, { Component } from 'react';
import Poi from './table/dna/poi/Poi.jsx'

function WLincolnshire(props) {

    const {className, theme, classes} = props;

    return (
        <div>
          <Poi></Poi>
        </div>
    );

}


export default WLincolnshire;
